import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

const TopBar = () => {
  const router = useRouter();
  const currentUrl = router.asPath;
  return (
    <div className="header-top">
      <div className="auto-container">
        <div className="wrapper-box box-style-one">
          <div className="left-column">
            <ul className="contact-info box-style-two">
              <li>
                <a href="mailto:management@eaglespyramidsview.com">
                  management@eaglespyramidsview.com
                </a>
              </li>
              <li>|</li>
              <li>
                <a href="tel:09806764956">098-067-649-56</a>
              </li>
            </ul>
          </div>
          <div className="right-column box-style-two">
            <div className="check-reservation">
              {currentUrl && currentUrl !== "/check-reservation" ? (
                <Link href="/check-reservation">
                  <a>Check Reservation</a>
                </Link>
              ) : (
                <Link href="/">
                  <a>Home</a>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
