import Image from "next/image";
import React from "react";

const Sidebar = ({ open, closeSidebar }) => {
  return (
    <section
      className={`hidden-sidebar  ${open ? "active-sidebar" : "close-sidebar"}`}
    >
      <div className="wrapper-box">
        <div className="content-wrapper">
          <div className="hidden-sidebar-close">
            <span className="flaticon-remove" onClick={() => closeSidebar()} />
          </div>
          <div className="about-widget widget">
            <div className="logo">
              <Image
                src="/assets/images/logo.svg"
                className="inverted-logo"
                width={100}
                height={100}
                alt=""
              />
            </div>
            <div className="text">
              Located in Cairo, 1.1 km from Great Sphinx, Eagles Pyramids View
              provides accommodation with a terrace, free private parking and a
              restaurant.
            </div>
          </div>
          <div className="instagram-widget widget">
            <h4>Instagram Feeds</h4>
            <div className="inner-box">
              <div className="wrapper-box">
                <div className="image">
                  <Image
                    width={80}
                    height={80}
                    src="/assets/images/news/news-ins-2.jpg"
                    alt=""
                  />
                  <div className="overlay-link">
                    <a
                      href="/assets/images/gallery/instagram-1.jpg"
                      className="lightbox-image"
                      data-fancybox="gallery"
                    >
                      <span className="fa fa-plus" />
                    </a>
                  </div>
                </div>
                <div className="image">
                  <Image
                    width={80}
                    height={80}
                    src="/assets/images/news/news-ins-3.jpg"
                    alt=""
                  />
                  <div className="overlay-link">
                    <a
                      href="/assets/images/gallery/instagram-2.jpg"
                      className="lightbox-image"
                      data-fancybox="gallery"
                    >
                      <span className="fa fa-plus" />
                    </a>
                  </div>
                </div>
                <div className="image">
                  <Image
                    width={80}
                    height={80}
                    src="/assets/images/news/news-ins-4.jpg"
                    alt=""
                  />
                  <div className="overlay-link">
                    <a
                      href="/assets/images/gallery/instagram-3.jpg"
                      className="lightbox-image"
                      data-fancybox="gallery"
                    >
                      <span className="fa fa-plus" />
                    </a>
                  </div>
                </div>
                <div className="image">
                  <Image
                    width={80}
                    height={80}
                    src="/assets/images/news/news-ins-5.jpg"
                    alt=""
                  />
                  <div className="overlay-link">
                    <a
                      href="/assets/images/gallery/instagram-4.jpg"
                      className="lightbox-image"
                      data-fancybox="gallery"
                    >
                      <span className="fa fa-plus" />
                    </a>
                  </div>
                </div>
                <div className="image">
                  <Image
                    width={80}
                    height={80}
                    src="/assets/images/news/news-ins-6.jpg"
                    alt=""
                  />
                  <div className="overlay-link">
                    <a
                      href="/assets/images/gallery/instagram-5.jpg"
                      className="lightbox-image"
                      data-fancybox="gallery"
                    >
                      <span className="fa fa-plus" />
                    </a>
                  </div>
                </div>
                <div className="image">
                  <Image
                    width={80}
                    height={80}
                    src="/assets/images/news/news-ins-7.jpg"
                    alt=""
                  />
                  <div className="overlay-link">
                    <a
                      href="/assets/images/gallery/instagram-6.jpg"
                      className="lightbox-image"
                      data-fancybox="gallery"
                    >
                      <span className="fa fa-plus" />
                    </a>
                  </div>
                </div>
                <div className="image">
                  <Image
                    width={80}
                    height={80}
                    src="/assets/images/news/news-ins-8.jpg"
                    alt=""
                  />
                  <div className="overlay-link">
                    <a
                      href="/assets/images/gallery/instagram-7.jpg"
                      className="lightbox-image"
                      data-fancybox="gallery"
                    >
                      <span className="fa fa-plus" />
                    </a>
                  </div>
                </div>
                <div className="image">
                  <Image
                    width={80}
                    height={80}
                    src="/assets/images/news/news-ins-9.jpg"
                    alt=""
                  />
                  <div className="overlay-link">
                    <a
                      href="/assets/images/gallery/instagram-8.jpg"
                      className="lightbox-image"
                      data-fancybox="gallery"
                    >
                      <span className="fa fa-plus" />
                    </a>
                  </div>
                </div>
                <div className="image">
                  <Image
                    width={80}
                    height={80}
                    src="/assets/images/news/news-ins.jpg"
                    alt=""
                  />
                  <div className="overlay-link">
                    <a
                      href="/assets/images/gallery/instagram-1.jpg"
                      className="lightbox-image"
                      data-fancybox="gallery"
                    >
                      <span className="fa fa-plus" />
                    </a>
                  </div>
                </div>
              </div>
              {/* /.gallery-wrapper */}
            </div>
          </div>
          <div className="widget contact-widget">
            <h4>Get In Touch</h4>
            <div className="text">
              Welcome to Eagles Pyramids View, where comfort is everything.
            </div>
            <ul>
              <li>
                <i className="fal fa-phone" />
                <a href="tel:+897 676 5654 654">+897 676 5654 654</a>
              </li>
              <li>
                <i className="fal fa-phone" />
                <a href="tel:+908(097) 564 765 76">+908(097) 564 765 76</a>
              </li>
              <br />
              <li>
                <i className="fal fa-envelope" />
                <a href="mailto:management@eaglespyramidsview.com">
                  management@eaglespyramidsview.com
                </a>
              </li>
              <li>
                <i className="fal fa-map-marker-alt" /> 9 tibaa street Nazlet el
                saman giza,
                <br />
                Giza, 12557 Cairo, Egypt
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Sidebar;
