import React from "react";
import TopBar from "./TopBar";

const HeaderTopBar = ({ headernumber }) => {
  return (
    <>
      <header
        className={`main-header header-style-one ${
          headernumber ? "" : "style-two"
        }`}
      >
        <TopBar />
      </header>
    </>
  );
};

export default HeaderTopBar;
