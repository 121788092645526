import Link from "next/link";
import { Fragment } from "react";

export const Pages = () => (
  <Fragment>
    <li>
      <Link href="/services/1">Services</Link>
    </li>
    <li>
      <Link href="/restaurant">Restaurant</Link>
    </li>
    <li>
      <Link href="/gallery">Gallery</Link>
    </li>
    <li>
      <Link href="/offers">Offers</Link>
    </li>
    <li>
      <Link href="/menu">Menu</Link>
    </li>
    <li>
      <Link href="/places">Places</Link>
    </li>
  </Fragment>
);
