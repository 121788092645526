import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { footerSlider } from "../../props/sliderProps";
import Link from "next/link";
import Image from "next/image";
import { useState } from "react";
import { useEffect } from "react";

const Footer = ({ noBg, extraCls }) => {
  const [isClient, setIsClient] = useState(false);

  const imageClassName = noBg ? "noBackground" : "inverted";

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <footer
      className={`main-footer ${extraCls ? extraCls : "style-two"}`}
      style={{
        background: noBg
          ? null
          : `url(/assets/images/background/footerBackground.jpg)`,
      }}
    >
      <div className="auto-container">
        <div className="widget-wrapper">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="footer-logo">
                <Image
                  src="/assets/images/logo.svg"
                  width="144"
                  height="119"
                  className={imageClassName}
                  alt=""
                />
              </div>
            </div>
            <div className="column col-lg-3 col-md-6">
              <div className="widget links-widget">
                <h4>Pages</h4>
                <div className="row">
                  <div className="col-sm-6">
                    <ul>
                      <li>
                        <a href="#">About</a>
                      </li>
                      <li>
                        <a href="#">Approach</a>
                      </li>
                      <li>
                        <a href="#">People</a>
                      </li>
                      <li>
                        <a href="#">Reviews</a>
                      </li>
                      <li>
                        <a href="#">Insights</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <ul>
                      <li>
                        <Link href="/services/1">Services</Link>
                      </li>
                      <li>
                        <a href="#">Our Menus</a>
                      </li>
                      <li>
                        <a href="#">Shop</a>
                      </li>
                      <li>
                        <Link href="/contact">Contact</Link>
                      </li>
                      <li>
                        <Link href="/terms-and-conditions">
                          Terms and Conditions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget text-widget">
                <h4>Our Awards</h4>
                <div className="text">
                  When your people get up every day <br /> wanting to come to
                  work.
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="logo">
                      <Image
                        width="49"
                        height="46"
                        src="/assets/images/resource/image-15.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="logo">
                      <Image
                        width="56"
                        height="39"
                        src="/assets/images/resource/image-16.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="logo">
                      <Image
                        width="50"
                        height="42"
                        src="/assets/images/resource/image-17.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget contact-widget">
                <h4>Get In Touch</h4>
                <div className="text">
                  Welcome to Eagles Pyramids View, where comfort is everything.
                </div>
                <ul>
                  <li>
                    <i className="fal fa-phone" />
                    <a href="tel:+897 676 5654 654">+897 676 5654 654</a>
                  </li>
                  <li>
                    <i className="fal fa-phone" />
                    <a href="tel:+908(097) 564 765 76">+908(097) 564 765 76</a>
                  </li>
                  <li>
                    <i className="fal fa-envelope" />
                    <a href="mailto:management@eaglespyramidsview.com">
                      management@eaglespyramidsview.com
                    </a>
                  </li>
                  <li>
                    <i className="fal fa-map-marker-alt" /> 9 tibaa street
                    Nazlet el saman giza, Giza, 12557 Cairo, Egypt
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Bottom */}
      <div className="footer-bottom">
        <div className="auto-container">
          <div className="wrapper-box">
            <div className="copyright">
              <div className="text">
                Copyright Eagles Pyramids View Hotel. All right reserved.
              </div>
            </div>
            <div className="brand-logo">
              {isClient && (
                <Swiper
                  {...footerSlider}
                  className="theme_carousel swiper-container"
                >
                  <SwiperSlide className="logo">
                    <Image
                      width="80"
                      height="48"
                      src="/assets/images/resource/mastercard.png"
                      alt=""
                    />
                  </SwiperSlide>
                  <SwiperSlide className="logo">
                    <Image
                      width="80"
                      height="44"
                      src="/assets/images/resource/visa.png"
                      alt=""
                    />
                  </SwiperSlide>
                  <SwiperSlide className="logo">
                    <Image
                      width="80"
                      height="29"
                      src="/assets/images/resource/amex.png"
                      alt=""
                    />
                  </SwiperSlide>
                </Swiper>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
