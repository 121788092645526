import React, { useEffect } from "react";
import ScrollTop from "../components/scroll-top.component";
import VideoPopup from "../components/video-pop-up.component";
import ImageView from "../components/image-view.component";
import Footer from "./footers/Footer";
import Header from "./headers/Header";
import HeaderTopBar from "./headers/HeaderTopBar";
import { animation, niceSelect, stickyNav } from "../utils/commonUtils";

const Layouts = ({
  children,
  noBg,
  extraCls,
  noHeader,
  noFooter,
  topBar,
  headernumber,
}) => {
  useEffect(() => {
    animation();
    niceSelect({ withoutwide: true });
    window.addEventListener("scroll", stickyNav);
  }, []);

  return (
    <div className="page-wrapper">
      <ImageView />
      <VideoPopup />
      {!noHeader && <Header headernumber={headernumber} />}
      {topBar && <HeaderTopBar headernumber={headernumber} />}
      {children}
      {!noFooter && <Footer noBg={noBg} extraCls={extraCls} />}
      <ScrollTop />
    </div>
  );
};
export default Layouts;
