import Link from "next/link";
import React, { useState } from "react";
import { Pages } from "./Menu";
import Image from "next/image";

const MobileHeader = ({ closeMobileHeader }) => {
  const [toggle, setToggle] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const activeMenuSet = (value) =>
      setActiveMenu(activeMenu === value ? "" : value),
    activeLi = (value) =>
      value === activeMenu ? { display: "block" } : { display: "none" },
    activeIcon = (value) => (value === activeMenu ? "open" : "");
  return (
    <div className="mobile-menu">
      <div className="menu-backdrop" />
      <div className="close-btn" onClick={() => closeMobileHeader()}>
        <i className="icon far fa-times" />
      </div>
      <nav className="menu-box">
        <div className="nav-logo">
          <Link href="/">
            <a>
              <Image
                width={100}
                height={100}
                src="/assets/images/logo.svg"
                className="inverted-logo"
                alt=""
                title=""
              />
            </a>
          </Link>
        </div>
        <div className="menu-outer">
          {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
          <div className="collapse navbar-collapse show clearfix">
            <ul className="navigation">
              <li className="dropdown current">
                <Link href="/">Home</Link>
              </li>
              <li>
                <Link href="/about">About Us </Link>
              </li>
              <li>
                <Link href="/room-list/1">Rooms</Link>
              </li>
              <li>
                <Link href="/tours/1">Tours</Link>
              </li>
              <li className="dropdown">
                <a href="#">Pages</a>
                <ul style={activeLi("Pages")}>
                  <Pages />
                </ul>
                <div
                  className={`dropdown-btn ${activeIcon("Pages")}`}
                  onClick={() => activeMenuSet("Pages")}
                >
                  <span className="fa fa-angle-right" />
                </div>
              </li>
              <li>
                <Link href="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};
export default MobileHeader;
